exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-5-criteri-js": () => import("./../../../src/pages/blog/5criteri.js" /* webpackChunkName: "component---src-pages-blog-5-criteri-js" */),
  "component---src-pages-blog-5-motivi-js": () => import("./../../../src/pages/blog/5motivi.js" /* webpackChunkName: "component---src-pages-blog-5-motivi-js" */),
  "component---src-pages-blog-agile-js": () => import("./../../../src/pages/blog/agile.js" /* webpackChunkName: "component---src-pages-blog-agile-js" */),
  "component---src-pages-blog-bisogno-di-un-app-se-js": () => import("./../../../src/pages/blog/bisogno-di-un-app-se.js" /* webpackChunkName: "component---src-pages-blog-bisogno-di-un-app-se-js" */),
  "component---src-pages-blog-ci-cd-js": () => import("./../../../src/pages/blog/ci-cd.js" /* webpackChunkName: "component---src-pages-blog-ci-cd-js" */),
  "component---src-pages-blog-cyber-security-js": () => import("./../../../src/pages/blog/cyber_security.js" /* webpackChunkName: "component---src-pages-blog-cyber-security-js" */),
  "component---src-pages-blog-dapp-js": () => import("./../../../src/pages/blog/dapp.js" /* webpackChunkName: "component---src-pages-blog-dapp-js" */),
  "component---src-pages-blog-data-processing-js": () => import("./../../../src/pages/blog/data-processing.js" /* webpackChunkName: "component---src-pages-blog-data-processing-js" */),
  "component---src-pages-blog-docker-js": () => import("./../../../src/pages/blog/docker.js" /* webpackChunkName: "component---src-pages-blog-docker-js" */),
  "component---src-pages-blog-ecommerce-js": () => import("./../../../src/pages/blog/ecommerce.js" /* webpackChunkName: "component---src-pages-blog-ecommerce-js" */),
  "component---src-pages-blog-ecosistema-react-js": () => import("./../../../src/pages/blog/ecosistema_react.js" /* webpackChunkName: "component---src-pages-blog-ecosistema-react-js" */),
  "component---src-pages-blog-ethereum-js": () => import("./../../../src/pages/blog/ethereum.js" /* webpackChunkName: "component---src-pages-blog-ethereum-js" */),
  "component---src-pages-blog-formik-js": () => import("./../../../src/pages/blog/formik.js" /* webpackChunkName: "component---src-pages-blog-formik-js" */),
  "component---src-pages-blog-gestionale-personalizzato-js": () => import("./../../../src/pages/blog/gestionale_personalizzato.js" /* webpackChunkName: "component---src-pages-blog-gestionale-personalizzato-js" */),
  "component---src-pages-blog-happy-software-house-js": () => import("./../../../src/pages/blog/happy_software_house.js" /* webpackChunkName: "component---src-pages-blog-happy-software-house-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-kubernetes-js": () => import("./../../../src/pages/blog/kubernetes.js" /* webpackChunkName: "component---src-pages-blog-kubernetes-js" */),
  "component---src-pages-blog-machine-learning-js": () => import("./../../../src/pages/blog/machine-learning.js" /* webpackChunkName: "component---src-pages-blog-machine-learning-js" */),
  "component---src-pages-blog-mern-js": () => import("./../../../src/pages/blog/mern.js" /* webpackChunkName: "component---src-pages-blog-mern-js" */),
  "component---src-pages-blog-metaverso-js": () => import("./../../../src/pages/blog/metaverso.js" /* webpackChunkName: "component---src-pages-blog-metaverso-js" */),
  "component---src-pages-blog-next-js": () => import("./../../../src/pages/blog/next.js" /* webpackChunkName: "component---src-pages-blog-next-js" */),
  "component---src-pages-blog-nomadismo-digitale-js": () => import("./../../../src/pages/blog/nomadismo_digitale.js" /* webpackChunkName: "component---src-pages-blog-nomadismo-digitale-js" */),
  "component---src-pages-blog-prestazioni-react-js": () => import("./../../../src/pages/blog/prestazioni_react.js" /* webpackChunkName: "component---src-pages-blog-prestazioni-react-js" */),
  "component---src-pages-blog-react-360-js": () => import("./../../../src/pages/blog/react-360.js" /* webpackChunkName: "component---src-pages-blog-react-360-js" */),
  "component---src-pages-blog-react-animated-js": () => import("./../../../src/pages/blog/reactAnimated.js" /* webpackChunkName: "component---src-pages-blog-react-animated-js" */),
  "component---src-pages-blog-react-apollo-js": () => import("./../../../src/pages/blog/react-apollo.js" /* webpackChunkName: "component---src-pages-blog-react-apollo-js" */),
  "component---src-pages-blog-react-auth-0-js": () => import("./../../../src/pages/blog/react-auth0.js" /* webpackChunkName: "component---src-pages-blog-react-auth-0-js" */),
  "component---src-pages-blog-react-aws-js": () => import("./../../../src/pages/blog/react-AWS.js" /* webpackChunkName: "component---src-pages-blog-react-aws-js" */),
  "component---src-pages-blog-react-axios-js": () => import("./../../../src/pages/blog/react-axios.js" /* webpackChunkName: "component---src-pages-blog-react-axios-js" */),
  "component---src-pages-blog-react-bundling-js": () => import("./../../../src/pages/blog/react-bundling.js" /* webpackChunkName: "component---src-pages-blog-react-bundling-js" */),
  "component---src-pages-blog-react-crud-js": () => import("./../../../src/pages/blog/react-CRUD.js" /* webpackChunkName: "component---src-pages-blog-react-crud-js" */),
  "component---src-pages-blog-react-error-js": () => import("./../../../src/pages/blog/react-error.js" /* webpackChunkName: "component---src-pages-blog-react-error-js" */),
  "component---src-pages-blog-react-ethereum-js": () => import("./../../../src/pages/blog/react-ethereum.js" /* webpackChunkName: "component---src-pages-blog-react-ethereum-js" */),
  "component---src-pages-blog-react-fetching-js": () => import("./../../../src/pages/blog/react-fetching.js" /* webpackChunkName: "component---src-pages-blog-react-fetching-js" */),
  "component---src-pages-blog-react-graph-ql-js": () => import("./../../../src/pages/blog/react-GraphQL.js" /* webpackChunkName: "component---src-pages-blog-react-graph-ql-js" */),
  "component---src-pages-blog-react-io-t-js": () => import("./../../../src/pages/blog/react-IoT.js" /* webpackChunkName: "component---src-pages-blog-react-io-t-js" */),
  "component---src-pages-blog-react-open-ai-js": () => import("./../../../src/pages/blog/react-openAI.js" /* webpackChunkName: "component---src-pages-blog-react-open-ai-js" */),
  "component---src-pages-blog-react-performance-js": () => import("./../../../src/pages/blog/react-performance.js" /* webpackChunkName: "component---src-pages-blog-react-performance-js" */),
  "component---src-pages-blog-react-profiler-js": () => import("./../../../src/pages/blog/reactProfiler.js" /* webpackChunkName: "component---src-pages-blog-react-profiler-js" */),
  "component---src-pages-blog-react-query-js": () => import("./../../../src/pages/blog/react_query.js" /* webpackChunkName: "component---src-pages-blog-react-query-js" */),
  "component---src-pages-blog-react-singleton-js": () => import("./../../../src/pages/blog/react-singleton.js" /* webpackChunkName: "component---src-pages-blog-react-singleton-js" */),
  "component---src-pages-blog-react-stripe-js": () => import("./../../../src/pages/blog/react-stripe.js" /* webpackChunkName: "component---src-pages-blog-react-stripe-js" */),
  "component---src-pages-blog-react-vr-js": () => import("./../../../src/pages/blog/react-vr.js" /* webpackChunkName: "component---src-pages-blog-react-vr-js" */),
  "component---src-pages-blog-react-web-socket-js": () => import("./../../../src/pages/blog/react-WebSocket.js" /* webpackChunkName: "component---src-pages-blog-react-web-socket-js" */),
  "component---src-pages-blog-redux-js": () => import("./../../../src/pages/blog/redux.js" /* webpackChunkName: "component---src-pages-blog-redux-js" */),
  "component---src-pages-blog-specifiche-js": () => import("./../../../src/pages/blog/specifiche.js" /* webpackChunkName: "component---src-pages-blog-specifiche-js" */),
  "component---src-pages-blog-sql-no-sql-js": () => import("./../../../src/pages/blog/SQL-NoSQL.js" /* webpackChunkName: "component---src-pages-blog-sql-no-sql-js" */),
  "component---src-pages-blog-user-experience-js": () => import("./../../../src/pages/blog/user-experience.js" /* webpackChunkName: "component---src-pages-blog-user-experience-js" */),
  "component---src-pages-blog-virtual-learning-js": () => import("./../../../src/pages/blog/virtual_learning.js" /* webpackChunkName: "component---src-pages-blog-virtual-learning-js" */),
  "component---src-pages-blog-webapp-js": () => import("./../../../src/pages/blog/webapp.js" /* webpackChunkName: "component---src-pages-blog-webapp-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-landing-js": () => import("./../../../src/pages/contactLanding.js" /* webpackChunkName: "component---src-pages-contact-landing-js" */),
  "component---src-pages-crm-js": () => import("./../../../src/pages/crm.js" /* webpackChunkName: "component---src-pages-crm-js" */),
  "component---src-pages-happy-developers-js": () => import("./../../../src/pages/happy-developers.js" /* webpackChunkName: "component---src-pages-happy-developers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-l-outsourcing-con-appius-js": () => import("./../../../src/pages/l-outsourcing-con-appius.js" /* webpackChunkName: "component---src-pages-l-outsourcing-con-appius-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-outsourcing-con-appius-js": () => import("./../../../src/pages/outsourcing-con-appius.js" /* webpackChunkName: "component---src-pages-outsourcing-con-appius-js" */),
  "component---src-pages-outsourcing-js": () => import("./../../../src/pages/outsourcing.js" /* webpackChunkName: "component---src-pages-outsourcing-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-prenota-js": () => import("./../../../src/pages/prenota.js" /* webpackChunkName: "component---src-pages-prenota-js" */),
  "component---src-pages-solution-js": () => import("./../../../src/pages/solution.js" /* webpackChunkName: "component---src-pages-solution-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

